import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Layout from "../../components/Layout"
import { graphql } from "gatsby"

import Hero from "../../sections/hero/hero"
import ClientExp from "../../sections/clients_exp/clients_exp"
import Features from "../../sections/features/features"
import Services from "../../sections/services/services"
import CallToAction from "../../sections/call_to_action/call_to_action"
import Faq from "../../sections/faq/faq"
import LogoSlides from "../../sections/logo_slides/logo_slides"
import RecentWork from "../../sections/works/recentWork"
import Slides from "../../sections/slides/slides"
import Video from "../../sections/video_section/Video"
import About from "../../sections/about/about"
import MultipleCardFeatures from "../../sections/multiple_card_features/multiple_card_features"
import Solution from "../../sections/solutions/solutions"
import Stack from "../../sections/tech/stack"
import { getSeo } from "../../utils/seo"
import { getNextSection } from "../../utils/functions"
import { useCallback } from "react"
import {
  fetureStyle,
  CTAStyle,
  faqStyle,
  heroStyle,
  ServiceStyles,
  RecentWorkStyles,
  LogoSlidesStyles,
  ClientExpStyles,
  SlidesStyles,
  VideoStyles,
  AboutStyles,
  MultipleFeaturesStyle,
  SolutionStyles,
  TechStyles,
} from "../../utils/styles-loader"

export default function Page({ data, pageContext }) {
  const { page } = data
  const nextSection = getNextSection(page)
  const getSections = useCallback(section => {
    let styles
    switch (section._type) {
      case "hero":
        styles = heroStyle()
        return <Hero hero={section} styles={styles} nextSection={nextSection} />
      case "features":
        console.log(section)
        if (section.service) {
          return <Services content={section} styles={ServiceStyles()} />
        }
        styles = fetureStyle({
          style: section.style,
          scroll: section.slide,
          background: section.features[0].backgroundColor ? true : false,
        })
        return <Features features={section} styles={styles} />
      case "call_action":
        return (
          <CallToAction
            call={section}
            styles={CTAStyle(section.styles)}
            arrow={section.styles === "square" ? true : false}
          />
        )
      case "faq":
        return <Faq faq={section} styles={faqStyle()} />

      case "works":
        return (
          <RecentWork
            works={section}
            styles={RecentWorkStyles()}
            headerCenter={section.centerTitle}
          />
        )

      case "logo_slider":
        return <LogoSlides content={section} styles={LogoSlidesStyles()} />

      case "testimonials":
        return <ClientExp content={section} styles={ClientExpStyles()} />

      case "slides":
        return <Slides content={section} styles={SlidesStyles()} />

      case "video_section":
        return <Video content={section} styles={VideoStyles()} />

      case "about":
        return <About content={section} styles={AboutStyles()} />

      case "services":
        return (
          <MultipleCardFeatures
            features={section}
            styles={MultipleFeaturesStyle()}
          />
        )

      case "solution":
        return <Solution content={section} styles={SolutionStyles()} />

      case "stack":
        return <Stack stack={section} styles={TechStyles()} />

      default:
        return <></>
    }
  })

  return (
    <Layout navBG={""}>
      {page._rawSections?.map(section => {
        return getSections(section)
      })}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const { page } = data
  const seo = page._rawSections.find(section => section._type === "seo")
  return getSeo(seo)
}

export const query = graphql`
  query pageSeoDynamic($slug: String) {
    page: sanitySeoPage(slug: { current: { eq: $slug } }) {
      title
      _rawSections(resolveReferences: { maxDepth: 15 })
    }
  }
`
