//FEATURES

import * as inline_4 from "../sections/features/features_inline_4.module.scss"
import * as inline_5 from "../sections/features/features_inline_5.module.scss"
import * as inline_3 from "../sections/features/features_index.module.scss"
import * as style_scroll from "../sections/features/features_scroll.module.scss"
import * as icon_background from "../sections/features/icon_background.module.scss"

//CTA
import * as cta from "../sections/call_to_action/book.module.scss"
import * as cta_square from "../sections/call_to_action/action_square.module.scss"
import * as cta_basic from "../sections/call_to_action/call_to_action_index.module.scss"

//FAQ
import * as faq_styles from "../sections/faq/faq.module.scss"

//HERO
import * as hero_styles from "../sections/hero/hero_index.module.scss"

//SERVICES
import * as services_styles from "../sections/services/services.module.scss"

//WORKS
import * as works_styles from "../sections/works/works_index.module.scss"

//LOGO SLIDES
import * as logo_slide_styles from "../sections/logo_slides/logo_slides.module.scss"

//CLIENT EXP
import * as client_exp_styles from "../sections/clients_exp/clients_exp.module.scss"

//SLIDES
import * as slides_styles from "../sections/slides/slides.module.scss"

//VIDEO
import * as video_styles from "../sections/video_section/video_index.module.scss"

//ABOUT
import * as about_styles from "../sections/about/about.module.scss"

//MULTIPLE CARD FEATURES
import * as multiple_card_features_style from "../sections/multiple_card_features/multiple_card_features.module.scss"

//SOLUTIONS
import * as solution_styles from "../sections/solutions/solutions_index.module.scss"

//TECH
import * as tech_styles from "../sections/tech/tech_index.module.scss"

export const fetureStyle = props => {
  const { style, scroll, background } = props
  console.log(style, scroll, background)

  if (background) {
    return icon_background
  }
  if (scroll) {
    return style_scroll
  }
  switch (style) {
    case "3":
      return inline_3

    case "4":
      return inline_4

    case "5":
      return inline_5

    default:
      return inline_3
  }
}

export const ServiceStyles = props => {
  return services_styles
}

export const CTAStyle = props => {
  console.log(props)

  switch (props) {
    case "in-line":
      return cta
    case "square":
      return cta_square
    default:
      return cta
  }
}

export const faqStyle = props => {
  return faq_styles
}
export const heroStyle = props => {
  return hero_styles
}

export const RecentWorkStyles = props => {
  return works_styles
}

export const LogoSlidesStyles = props => {
  return logo_slide_styles
}

export const ClientExpStyles = props => {
  return client_exp_styles
}

export const SlidesStyles = props => {
  return slides_styles
}

export const VideoStyles = props => {
  return video_styles
}

export const AboutStyles = props => {
  return about_styles
}

export const MultipleFeaturesStyle = props => {
  return multiple_card_features_style
}

export const SolutionStyles = props => {
  return solution_styles
}

export const TechStyles = props => {
  return tech_styles
}
